<template>
  <div class="base-echart">
    <div ref="echartRef" :style="{ width: width, height: height }"></div>
  </div>
</template>

<script setup>
import { defineProps, onMounted, ref, watchEffect } from 'vue'
import useEcharts from './hooks/useEcharts'

const props = defineProps({
  options: {
    type: Array,
    required: true
  },
  height: {
    type: String,
    required: true
  },
  width: {
    type: String,
    required: true
  }
})

const echartRef = ref()

onMounted(() => {
  const { setOptions } = useEcharts(echartRef.value)

  watchEffect(() => {
    setOptions(props.options)
  })
})
</script>
